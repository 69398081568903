<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold text-uppercase">Audit Details</h1>
        </b-col>
      </b-row>

      <b-row
        class="no-gutters bg-white-border mt-4"
        v-bind:class="$isLoading ? 'd-none' : 'd-block'"
      >
        <b-col class="px-4 px-sm-5 py-4">
          <b-row>
            <b-col md="6">
              <div class="display-custom">
                <label class="control-label">Username</label>
                <div class="my-1">
                  <p class="">
                    {{ audit.username }}
                  </p>
                </div>
              </div>
            </b-col>
            <!-- <b-col md="6">
              <div class="display-custom">
                <label class="control-label">Activity Name</label>
                <div class="my-1">
                  <p class="">
                    {{ audit.activityName }}  
                  </p>
                </div>
              </div>
            </b-col> -->
          </b-row>

          <b-row>
            <b-col md="6">
              <div class="display-custom">
                <label class="control-label">Page</label>
                <div class="my-1">
                  <p class="">
                    {{ audit.page }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="display-custom">
                <label class="control-label">Action</label>
                <div class="my-1">
                  <p class="">
                    {{ audit.action }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <div class="display-custom">
                <label class="control-label">TimeStamp</label>
                <div class="my-1">
                  <p class="">
                    {{ audit.timeStamp }}
                  </p>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <div class="display-custom">
                <label class="control-label">IP Address</label>
                <div class="my-1">
                  <p class="">
                    {{ audit.ipAddress }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <div class="display-custom">
                <label class="control-label">Request Model</label>
                <div class="my-1">
                  <p v-if="audit.requestModel">
                    {{ audit.requestModel }}
                  </p>
                  <p v-else>-</p>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <div class="display-custom">
                <label class="control-label">Data Type</label>
                <div class="my-1">
                  <p v-if="audit.dataTypeId">
                    {{ audit.dataTypeId }}
                  </p>
                  <p v-else>-</p>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <div class="display-custom">
                <label class="control-label">Data</label>
                <div class="my-1">
                  <p v-if="audit.data">
                    {{ audit.data }}
                  </p>
                  <p v-else>-</p>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-button to="/audit" class="btn-details-set ml-md-2"
              >BACK</b-button
            >
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      auditId: this.$route.params.id,
      audit: {
        id: null,
        activityId: null,
        activityName: "",
        timeStamp: "",
        ipAddress: "",
        username: "",
        userGUID: "",
        requestModel: "",
        target: "",
        dataTypeId: "",
        data: "",
      },
    };
  },
  mounted: async function() {
    this.$isLoading = true;
    await this.getAuditDetail();
    this.$isLoading = false;
  },
  methods: {
    getAuditDetail: async function() {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/auditlog/${this.auditId}`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.audit = data.detail.auditLog;
      }
    },
  },
};
</script>

<style scoped>
.display-custom > label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px !important;
  font-weight: bold;
}

.display-custom > div > p {
  color: #16274a;
  font-size: 16px;
  font-weight: normal;
}
</style>
